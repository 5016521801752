import { richText } from '@/utils/html';
import { productThumb } from '@/utils/adapters/product';

export default ({ primary }, { enhancedLinkSerializer }) => {
  return {
    type: primary.type || 'standard',
    textContent: richText(primary.content),
    product:
      primary.product?.id &&
      productThumb(primary.product, enhancedLinkSerializer),
  };
};
